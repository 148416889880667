import React from "react";
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import {
  Container,
  Col,
  Row
} from 'reactstrap';
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import HeadMeta from '../components/HeadMeta';
import TriDymeIcon from '../media/TriDymeIcon.png';
import frSvg from '../components/Language/img/fr.svg';
import enSvg from '../components/Language/img/gb.svg';
import usSvg from '../components/Language/img/us.svg';
import SEO from '../components/SEO';
import locales from '../constants/locales';

const metaData = {
  en: {
    title: `TriDyme | About us`,
    description: "About us"
  },
  fr: {
    title: `TriDyme | A propos de nous`,
    description: "A propos de nous"
  }
};

const AboutPost = ({ pageContext: { locale }, data }) => {
  const post = data.markdownRemark
  return (
    <Layout locale={locale}>
      <SEO 
        title={`TriDyme | ${post.frontmatter.title}`}
        lang={locale}
        url={locales[locale].default 
          ? `/${post.fields.slug}`
          : `/${locales[locale].path}${post.fields.slug}`}
        description={post.frontmatter.description}
        author={post.frontmatter.author}
      />
      <Container className="blogPostsContainer">
        <Row className="articleTitle">
          <img 
            className="imgTitle"
            src={post.frontmatter.featuredImage.publicURL}
            alt={post.frontmatter.title}
            title={post.frontmatter.title}
          />
          <h1 className="textTitle">{post.frontmatter.title}</h1>
        </Row>
        <Row>
          <Col md={12}>
            <h1 className="title">{post.frontmatter.title}</h1>
            <div className="content" dangerouslySetInnerHTML={{ __html: post.html }} />
          </Col>
        </Row>
        <br />
      </Container>
    </Layout>
  )
}

AboutPost.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default AboutPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        author
        language
        description
        featuredImage {
          publicURL
        }
      }
      fields {
        slug
      }
    }
  }
`
